<script>
  import { onDestroy } from "svelte";
  import Control from "./Control.svelte";
  import { theme } from "./theme";

  let activeTheme;

  const unsubscribe = theme.subscribe(theme => (activeTheme = theme));

  const navToBlog = () => {
    window.open('https://dev.to/denichodev', '_blank', 'noopener');
  }

  onDestroy(unsubscribe);
</script>

<style>
  a {
    color: unset;
  }

  .nav {
    display: flex;
    align-items: center;
  }

  .nav.light {
    background-color: rgba(50, 50, 50, 0.09);
  }

  .tabs {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    display: flex;
    flex: 1;
    flex-flow: row;
  }

  .tab {
    user-select: none;
    display: flex;
    justify-content: center;
    height: 32px;
    align-items: center;
    list-style-type: none;
    flex: 1;
  }

  .tab.light {
    color: rgba(0, 0, 0, 0.3);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
  }

  .tab.dark {
    color: rgba(255, 255, 255, 0.2);
  }

  .tab.light:hover {
    color: rgba(0, 0, 0, 0.5);
  }

  .tab.dark:hover {
    color: rgba(255, 255, 255, 0.4);
  }

  .tab.active.light {
    border-bottom: none;
    border-left: 0.5px solid rgba(0, 0, 0, 0.12);
    border-right: 0.5px solid rgba(0, 0, 0, 0.12);
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.57);
  }

  .tab.active.dark {
    border-bottom: 2px solid #80cbc4;
    color: #ffffff;
  }

  .control-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
  }

  .control-wrapper.light {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
  }
</style>

<nav class="nav {activeTheme}">
  <div class="control-wrapper {activeTheme}">
    <Control />
  </div>
  <ul class="tabs">
    <li class="tab active {activeTheme}">~</li>
    <li class="tab {activeTheme}" on:click="{navToBlog}">blog</li>
  </ul>
</nav>
