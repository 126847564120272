<style>
  .control {
    margin-left: 10px;
    margin-right: 4px;
    display: flex;
  }

  .btn {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
</style>

<div class="control">
  <div class="btn" style='background-color: rgb(255, 69, 58);' />
  <div class="btn" style='background-color: rgb(255, 214, 10);' />
  <div class="btn" style='background-color: rgb(50, 215, 75);' />
</div>
