<script>
  import { theme, toggleTheme } from "./theme";

  function changeTheme() {
    toggleTheme();
  }
</script>

<style>
  .night-btn {
    position: absolute;
    right: 6px;
    bottom: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-sizing: border-box;
    border: none;
  }

  .night-btn.light {
    background: rgba(50, 50, 50, 0.09);
    fill: rgba(0, 0, 0, 0.3);
  }

  .night-btn.light:hover {
    fill: rgba(0, 0, 0, 0.57) !important;
  }

  .night-btn.dark {
    background: #263238;
    fill: #80cbc4;
  }

  .night-btn.dark:hover {
    fill: #91e2da;
  }
</style>

<button aria-label="Dark mode toggle button" class="night-btn {$theme}" on:click={changeTheme}>
  <svg
    version="1.1"
    class={$theme}
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480 480"
    style="enable-background:new 0 0 480 480;"
    xml:space="preserve">
    <g>
      <g>
        <path
          d="M459.782,347.328c-4.288-5.28-11.488-7.232-17.824-4.96c-17.76,6.368-37.024,9.632-57.312,9.632
          c-97.056,0-176-78.976-176-176c0-58.4,28.832-112.768,77.12-145.472c5.472-3.712,8.096-10.4,6.624-16.832
          S285.638,2.4,279.078,1.44C271.59,0.352,264.134,0,256.646,0c-132.352,0-240,107.648-240,240s107.648,240,240,240
          c84,0,160.416-42.688,204.352-114.176C464.55,360.032,464.038,352.64,459.782,347.328z" />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
</button>
