<script>
  import {
    setContext,
    getContext,
    onDestroy,
    afterUpdate,
    onMount
  } from "svelte";
  import { theme } from "./theme";
  import { detectMobile, isMobile } from "./mobile";
  import Nav from "./Nav.svelte";
  import createInfos from "./infos";
  import NightButton from "./NightButton.svelte";

  const blacklistKeys = ["ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"];
  let activeTheme;
  let input = "";
  let inputNode;
  $: infos = createInfos(activeTheme);

  const unsubscribe = theme.subscribe(theme => (activeTheme = theme));

  function handleWindowClick() {
    if (inputNode) {
      inputNode.focus();
    }
  }

  function handleKeydown(e) {
    if (blacklistKeys.includes(e.key)) {
      e.preventDefault();
    }
  }

  function selectLastCharacter() {
    inputNode.setSelectionRange(input.length, input.length);
  }

  onMount(() => {
    if (inputNode) {
      inputNode.focus();
    }

    isMobile.set(detectMobile());
  });

  onDestroy(unsubscribe);
</script>

<style>
  :global(input[type="text"]),
  :global(input[type="text"]):focus {
    user-select: none;
    color: #383a42;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    caret-color: transparent;
  }

  :global(input[type="text"].light),
  :global(input[type="text"].light):focus {
    color: #383a42;
  }

  :global(input[type="text"].dark),
  :global(input[type="text"].dark):focus {
    color: #fff;
  }

  :global(*) {
    box-sizing: border-box;
  }

  :global(a) {
    text-decoration: underline;
  }

  :global(body) {
    background-color: #263238;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Menlo", monospace;
    font-size: 16px;
  }

  .window {
    position: relative;
    max-width: 1080px;
    width: 80%;
    border-radius: 7px;
    height: 70vh;
    display: flex;
    flex-direction: column;
  }

  .window.light {
    background-color: #fafafa;
    color: #383a42;
  }

  .window.dark {
    background-color: #37474f;
    color: #fff;
  }

  .content {
    flex: 1;
    overflow: auto;
    margin: 0 16px;
    line-height: 1.428;
  }

  .response-wrapper {
    margin-bottom: 8px;
  }

  .response.light {
    color: #50a14f;
  }

  .response.dark {
    color: #9ece58;
  }

  :global(a.light):visited {
    color: #50a14f;
  }

  :global(a.dark):visited {
    color: #9ece58;
  }

  .caret {
    width: 10px;
    height: 16px;
    align-self: center;
    user-select: none;
    position: relative;
    left: -2px;
  }

  .caret.light {
    background-color: #526eff;
  }

  .caret.dark {
    background-color: #faed70;
  }

  :global(a) {
    color: inherit;
  }

  :global(a):hover {
    color: #82aaff !important;
  }

  .line {
    display: flex;
    max-height: 18px;
    margin-bottom: 4px;
  }

  .line::before {
    content: "➤ \00a0";
  }

  .input {
    caret-color: transparent;
    color: #383a42;
    background: none;
    border: none;
    outline: none;
    flex: 1;
  }

  @media (max-width: 765px) {
    :global(body) {
      padding: 0;
    }

    .window {
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }

  :global(.skip-link) {
    position: absolute;
    top: -40px;
    left: 0;
    background: #000000;
    color: white;
    padding: 8px;
    z-index: 100;
  }

  :global(.skip-link:focus) {
    top: 0;
  }
</style>

<svelte:window on:click={handleWindowClick} />
<div class="window {activeTheme}">
  <Nav />
  <main id="main-content" class="content">
    <br />
    {#each infos as info (info.id)}
      <div class="line">echo ${info.command}</div>
      <div class="response-wrapper">
        <span class="response {activeTheme}">
          {@html info.response}
        </span>
      </div>
    {/each}
    <div class="line">

      <div class="caret {activeTheme}" style="visibility: visible" />
    </div>
    <NightButton />
  </main>
</div>
